import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import ErrorImage from '../assets/images/error.jpg';
import ContentMediaBlock from '../components/content-media-block';
import Layout from '../components/layout';
import { SectionHeading } from '../components/type';
import { COLORS, URLS } from '../constants';
import { withAppProps, withAppProviders } from '../utils/withAppProps';

const imageProps = {
  url: ErrorImage,
  description: 'Error Page',
};

const heading = 'Something went wrong...';

const callToAction = {
  label: 'Return home',
  href: URLS.HOME,
};

const StyledLayout = styled(Layout)`
  background-color: ${COLORS.WHITE};
`;

const Error = ({ statusCode = 400 }) => {
  const headingRef = React.useRef();

  React.useEffect(() => {
    if (headingRef.current) {
      // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'focus' does not exist on type 'never'.
      headingRef.current.focus();
    }
  }, [headingRef]);

  return (
    <StyledLayout>
      <ContentMediaBlock {...{ imageProps, heading, callToAction }} isReverse>
        {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type 'Element' is not assignable to type 'null | undefined'. */}
        <SectionHeading tabIndex="-1" ref={headingRef}>
          {statusCode === 404
            ? 'The page you are looking for may have been removed, had its name changed or is temporarily unavailable.'
            : 'Something went wrong on our end. We’re looking into it.'}
        </SectionHeading>
      </ContentMediaBlock>
    </StyledLayout>
  );
};

Error.propTypes = {
  statusCode: PropTypes.number,
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'res' implicitly has an 'any' type.
const getServerSideProps = withAppProps(({ res, err }) => {
  let statusCode = 404;
  if (res) {
    statusCode = res.statusCode;
  } else if (err) {
    statusCode = err.statusCode;
  }
  return { props: { statusCode } };
});

export default withAppProviders(Error);
export { getServerSideProps };
