import _ from 'lodash';
import Container from '@mui/material/Container';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
// @ts-expect-error [🤖 PLEASE FIX 🤖]: Could not find a declaration file for module 'request-ip'. '/Users/aaronpierce/workspace/tac-hsiyc-web/node_modules/request-ip/lib/index.js' implicitly has an 'any' type.
import requestIp from 'request-ip';

import Error from '../_error';
import { SPACE } from '../../constants';
import { H1 } from '../../components/type';
import Breadcrumbs from '../../components/breadcrumbs';
import Layout from '../../components/layout';
import TextLinksList from '../../components/text-links-list';
import { fetchModels } from '../../utils/api';
import constructSlug from '../../utils/construct-slug';
import logger from '../../utils/logger';
import { validateQueryParameter } from '../../utils/validate-query-parameter';
import setStatusCode from '../../utils/set-status-code';
import { withAppProps, withAppProviders } from '../../utils/withAppProps';

const SpaceX2 = styled.div`
  margin-top: ${SPACE.X2};
`;

const SpaceX1 = styled.div`
  margin-top: ${SPACE.X1};
`;

const StyledMake = styled(H1)`
  margin-bottom: ${
    /* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'hasExtraSpacing' does not exist on type '{ theme?: Theme | undefined; as?: ElementType<any, keyof IntrinsicElements> | undefined; } & ClassAttributes<HTMLHeadingElement> & HTMLAttributes<...> & { ...; } & { ...; }'. */
    (props) => (props.hasExtraSpacing ? SPACE.X3 : `calc(${SPACE.X1} + ${SPACE.X0})`)
  };
`;

const MakePage = ({ models = [], statusCode = 200 }) => {
  const titleRef = React.useRef();

  React.useEffect(() => {
    if (titleRef.current) {
      // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'focus' does not exist on type 'never'.
      titleRef.current.focus();
    }
  }, [titleRef]);

  if (statusCode !== 200) {
    return <Error {...{ statusCode }} />;
  }

  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Object is possibly 'undefined'.
  const title = _.first(models).makeDescription;
  const entryDeep = {
    title,
    ancestors: [{ title: 'Search', url: '/' }],
  };

  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'make' implicitly has an 'any' type.
  const getModelPath = (make, model) => `/${constructSlug(make)}/${constructSlug(model)}`;

  return (
    <Layout>
      <Container maxWidth="xl">
        <SpaceX2 />
        <Breadcrumbs entry={entryDeep} />
        <SpaceX1 />
        {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type 'string' is not assignable to type 'number'. */}
        <StyledMake tabIndex="-1" ref={titleRef}>
          {title}
        </StyledMake>
        <TextLinksList>
          <ul>
            {_.map(models, ({ modelId, makeDescription, modelDescription }) => (
              <li key={modelId}>
                <Link href="/[make]/[model]" as={getModelPath(makeDescription, modelDescription)}>
                  {modelDescription}
                </Link>
              </li>
            ))}
          </ul>
        </TextLinksList>
      </Container>
    </Layout>
  );
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'query' implicitly has an 'any' type.
const getServerSideProps = withAppProps(async ({ query, req, res }) => {
  const { make } = query;

  const isInputValid = validateQueryParameter(make);

  if (!isInputValid) {
    logger.error('Invalid make entered', {
      make,
      userIp: requestIp.getClientIp(req),
      userAgent: req.headers['User-Agent'],
      headers: req.headers,
    });
    return setStatusCode(404, res);
  }

  const data = await fetchModels(make);

  const { models, error } = data;

  if (error) {
    return setStatusCode(500, res);
  }

  if (_.isEmpty(models)) {
    logger.warn('Empty models for make', { make });
    return setStatusCode(404, res);
  }

  return { props: { models } };
});

MakePage.propTypes = {
  models: PropTypes.arrayOf(PropTypes.shape({})),
  statusCode: PropTypes.number,
};

export default withAppProviders(MakePage);
export { getServerSideProps };
